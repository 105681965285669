
html {
    background: url(images/coffee_on_table.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    overflow: hidden;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

#App{
    height: 100%;
}

.heading {
  color: lightslategrey;
}

.footer {
    color: lime;                      
    position: fixed;
    bottom: 0;
    height: 100px;
    width: 100%;
}

.tab_support {
    width: 80%;
    height: 80%;
}

.tab_home {
    width: 80%;
    height: 80%;
}

.tutorial {
    width: 80%;
    height: 80%;
    padding: 50px;
}
 
